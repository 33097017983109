<script>
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  name: 'GridGallery',

  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    devise: { type: Object },
    canSee: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      swiper: null,
      showVideo: false,
    }
  },

  mounted() {
    this.updateOnInit()

    if (
      this.devise.fields.wistiaId.enabled
      && this.devise.fields.videoMode.value === 'playAutomatically'
    )
      this.showVideo = true
  },

  methods: {
    updateOnInit() {
      setTimeout(() => {
        if (this.$refs.mySwiper?.$el?.swiper) {
          this.$refs.mySwiper.$el.swiper.update()
          this.swiper = this.$refs.mySwiper.$el.swiper
        }
        else {
          this.updateOnInit()
        }
      }, 1000)
    },

    next() {
      this.swiper.slideNext()
    },

    prev() {
      this.swiper.slidePrev()
    },

    goToSlide(index) {
      this.swiper.slideTo(index)
    },

    goToLink(link) {
      if (link.enabled)
        window.location.href = link.value.href
    },
  },
}
</script>

<template>
  <div class="relative">
    <div class="relative grid-cols-4 w-full gap-2 hidden lg:grid">
      <div
        class="col-span-3 grid-rows-12 grid transform duration-500 delay-300"
        :class="{
          'col-start-2 row-start-1 items-end': devise.fields.bigImageOnRight.value,
          'group cursor-pointer': devise.fields.link1.enabled,
          'translate-y-12 opacity-0': !canSee,
          'opacity-100': canSee,
        }"
        @click="goToLink(devise.fields.link1)"
      >
        <div class="relative h-full row-span-9 overflow-hidden">
          <VoixMedia v-if="!showVideo" :field="devise.fields.image1" class="object-cover w-full h-full" :class="{ 'group-hover:scale-105 transform duration-300': devise.fields.link1.enabled }" />

          <div
            v-else
            class="w-full h-full overflow-hidden object-cover  flex justify-center items-center bg-black"
            :style="sliderStyles"
          >
            <glue-media-wistia
              :video-id="devise.fields.wistiaId.value"
              class="w-full aspect-video relative z-30"
            />
          </div>
          <div
            class="absolute bottom-0 bg-white h-12 w-96"
            :class="{
              'left-0': !devise.fields.bigImageOnRight.value,
              'right-0': devise.fields.bigImageOnRight.value,
            }"
          />

          <div
            v-if="
              devise.fields.wistiaId
                && devise.fields.wistiaId.enabled
                && devise.fields.videoMode.value === 'clickToActivate'
                && !showVideo
            "
            class="absolute z-20 inset-0 m-8 text-zinc-100 flex justify-center items-center"
          >
            <button @click="showVideo = true">
              <svg
                width="100"
                height="100"
                viewBox="0 0 62 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="31"
                  cy="31"
                  r="30"
                  fill="black"
                  fill-opacity="0.4"
                  stroke="white"
                  stroke-width="1.5"
                />

                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  transform="translate(24 21)"
                  d="M16.4265 9.12584C17.1123 9.50685 17.1123 10.4932 16.4265 10.8742L1.48564 19.1746C0.819113 19.5449 0 19.063 0 18.3005L0 1.69951C0 0.93703 0.819113 0.455062 1.48564 0.825357L16.4265 9.12584Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          class="row-span-3"
          :class="{
            'flex justify-end h-full': devise.fields.bigImageOnRight.value,
          }"
        >
          <div class="bg-white h-full w-96 flex items-center">
            <div class="p-10 pt-0">
              <div class="headline-3 mb-2">
                {{ devise.fields.image1Title.value }}
              </div>
              <div
                class="font-light text-[17px] text-zinc-600"
                :class="{ ' line-clamp-4': devise.fields.clampDescriptions.value }"
              >
                {{ devise.fields.image1Description.value }}
              </div>
              <a
                v-if="devise.fields.link1.enabled"
                :href="devise.fields.link1.value?.href"
                :target="devise.fields.link1.value.target"
                class="mt-3 glue-btn btn-ghost border-zinc-500 text-zinc-700 uppercase body-11 tracking-widest  font-bold"
              >
                {{ devise.fields.link1.value.text }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-rows-12 grid">
        <div
          class="row-span-3 overflow-hidden transform duration-500 delay-500"
          :class="{
            'row-start-1 col-start-1': devise.fields.bigImageOnRight.value,
            'group cursor-pointer': devise.fields.link2.enabled,
            'translate-y-12 opacity-0': !canSee,
            'opacity-100': canSee,
          }"
          @click="goToLink(devise.fields.link2)"
        >
          <VoixMedia :field="devise.fields.image2" class="object-cover w-full h-full" :class="{ 'group-hover:scale-105 transform duration-300': devise.fields.link2.enabled }" />
        </div>
        <div
          class="row-span-3 bg-white p-10 flex flex-col justify-center transform duration-500 delay-500"
          :class="{
            'translate-y-12 opacity-0': !canSee,
            'opacity-100': canSee,
          }"
        >
          <div class="headline-3 mb-2">
            {{ devise.fields.image2Title.value }}
          </div>
          <div
            class="font-light text-[17px] text-zinc-600"
            :class="{ 'line-clamp-3': devise.fields.clampDescriptions.value }"
          >
            {{ devise.fields.image2Description.value }}
          </div>
          <a
            v-if="devise.fields.link2.enabled"
            :href="devise.fields.link2.value?.href"
            :target="devise.fields.link2.value.target"
            class="mt-3 glue-btn btn-ghost  border-zinc-500 text-zinc-700 uppercase body-11 tracking-widest  font-bold"
          >
            {{ devise.fields.link2.value.text }}
          </a>
        </div>
        <div
          class="row-span-3 overflow-hidden transform duration-500 delay-700"
          :class="{
            'group cursor-pointer': devise.fields.link3.enabled,
            'translate-y-12 opacity-0': !canSee,
            'opacity-100': canSee,
          }"
          @click="goToLink(devise.fields.link3)"
        >
          <VoixMedia :field="devise.fields.image3" class="object-cover w-full h-full" :class="{ 'group-hover:scale-105 transform duration-300': devise.fields.link3.enabled }" />
        </div>
        <div
          class="row-span-3 bg-white p-10 flex flex-col justify-center transform duration-500 delay-70"
          :class="{
            'translate-y-12 opacity-0': !canSee,
            'opacity-100': canSee,
          }"
        >
          <div class="headline-3 mb-2">
            {{ devise.fields.image3Title.value }}
          </div>
          <div
            class="font-light text-[17px] text-zinc-600"
            :class="{ 'line-clamp-3': devise.fields.clampDescriptions.value }"
          >
            {{ devise.fields.image3Description.value }}
          </div>
          <a
            v-if="devise.fields.link3.enabled"
            :href="devise.fields.link3.value?.href"
            :target="devise.fields.link3.value.target"
            class="mt-3 glue-btn btn-ghost  border-zinc-500 text-zinc-700 uppercase body-11 tracking-widest  font-bold"
          >
            {{ devise.fields.link3.value.text }}
          </a>
        </div>
      </div>
    </div>

    <div class="block lg:hidden pl-8">
      <Swiper
        ref="mySwiper"
        slides-per-view="auto"
        :space-between="10"
        :centered-slides="false"
        class="flex items-stretch"
        style="height: 500px"
      >
        <SwiperSlide
          v-for="n in 3"
          :key="n"
          class="flex items-stretch"
          style="width: 300px"
        >
          <div class="flex justify-center h-full" style="width: 300px">
            <div class="bg-white h-full">
              <VoixMedia :field="devise.fields[`image${n}`]" class="w-full" />
              <div class="bg-white p-10">
                <div class="headline-3 mb-2">
                  {{ devise.fields[`image${n}Title`].value }}
                </div>
                <div class="body text-zinc-600 line-clamp-4">
                  {{ devise.fields[`image${n}Description`].value }}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>
